import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { debounceTime, distinctUntilChanged, EMPTY, map, switchMap } from 'rxjs';
import { PageTourService } from 'src/app/service/page-tour.service';
import { PageVideoService } from 'src/app/service/page-video.service';
import { RouterEventsService } from 'src/app/service/router-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  title = 'DocketScope';

  constructor(
    private routerEventsService: RouterEventsService,
    private pageVideoService: PageVideoService,
    private pageTourService: PageTourService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.routerEventsService.routerEvents.pipe(
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap(route => route?.data || EMPTY),
    ).subscribe(({title: pageTitle, pageRefCd}) => {
      this.titleService.setTitle(pageTitle ? this.title + ' | ' + pageTitle : this.title);
      this.pageTourService.setTour(pageRefCd);
      this.pageVideoService.setPageVideos(pageRefCd);
    });
  }
}
